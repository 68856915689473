import { css } from "styled-components";
import { color } from "./color";

const border = css`
  border-radius: 10px;
  border: 1px solid ${color.grey_l2};
`;

const input = css`
  appearance: none;
  padding: 10px;
  background-color: ${color.white};
  ${border} ::placeholder {
    color: ${color.grey_l1};
  }
  :hover {
    cursor: pointer;
  }
`;

const select = css`
  ${input};
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACXElEQVR4nO3bMW4UQRRF0WvkmAASloC8C0RIgiz2Uo0cj5lmJRZiHywAm9ixV4AIMAG2A0fzp6te/a5+N3WXpnTG8rir/5zcc49r34veG9hKhhZlaFGGFmVoUYYWZWhRhhZlaFGGFmVoUYYWZWhRhhZlaFGGFmVoUYYWZWhRhhZlaFGGFmVoUYYWdRq5eN7PL4EdcA68YVtv1B/gGvhSpvI9uvjk0EmlB+QfwFn0RQaslKl8jSyI/EbuMPJju3k/v40siEB/Cm5m5E6Bj5EFEehXsb0M3+vIxRHo6+BGRu8mcnEEeg5uZORugavIgoOhy1S+AZfBDY3YHfChTOV3ZFHo/+AylQtgH1kzWHfA+zKVX9GF4RuOMpXPbBP7Efmoz6qj7uw2iL0IGRbcQm8IezEyLDyr2AB2FWSocCg0MHY1ZKh0+jYgdlVkqHjMORB2dWSofJ48AHYTZGhwcL9i7GbI0OgJyQqxmyJDw0dRK8JujgyNn/mtAFuCDIKHq4mxZcggeoqdEFuKDMJxgUTYcmQQz2UkwO6CDB0GYDpid0OGTpNGHbC7IkPHkS4hdndk6Dw7J8BOgQwJhhQbYqdBhgTQ0AQ7FTIkgYaq2OmQIRE0VMFOiQzJoGERdlpkSAgNR2GnRoak0BDCTo8MiaHhIOxVIENyaHjCvgD+PvvRLfBuDcgQ+LJQ7x6+M3LO/0n7n8BVdHS2Z6uBXnvp/3SMkqFFGVqUoUUZWpShRRlalKFFGVqUoUUZWpShRRlalKFFGVqUoUUZWpShRRlalKFFGVqUoUUZWpShRRla1D8DIddebux7RgAAAABJRU5ErkJggg==")
    no-repeat scroll 0 0 transparent;
  background-position: 99% 50%;
  background-size: 15px 15px;
`;

export const form = {
  select,
  input,
  border,
};
