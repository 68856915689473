import { css } from "styled-components";
import { flex } from "./flex";
import { color } from "./color";
import { convertColor } from "../common/utils";

const hover = css`
  :hover {
    cursor: pointer;
  }
`;

const base = css`
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 1.3em;
  ${flex.row};
  ${flex.center};
  ${hover};
`;

const primary = css`
  background-color: ${color.primary};
  color: ${color.white};
  :hover {
    background-color: ${convertColor.darken(color.primary)};
  }
`;

const secondary = css`
  background-color: ${color.secondary};
  color: ${color.white};
  :hover {
    background-color: ${convertColor.darken(color.secondary)};
  }
`;

const error = css`
  background-color: ${color.red};
  color: ${color.white};
  :hover {
    background-color: ${color.red};
    background-color: ${convertColor.darken(color.secondary)};
  }
`;

const success = css`
  background-color: ${color.green};
  color: ${color.white};
  :hover {
    background-color: ${convertColor.darken(color.green)};
  }
`;

const none = css`
  border: none;
  background: none;
`;

export const button = {
  base,
  primary,
  secondary,
  error,
  success,
  none,
  hover,
};
