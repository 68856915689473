import React, { Fragment, ReactNode, useEffect } from "react";
import styled from "styled-components";
import "./layout.css";
import { styles } from "../styles";
import { Header } from "./header";
import { convertColor } from "../common/utils";
import { useModal } from "../components/modal";
import { Button } from "@material-ui/core";

interface Props {
  children?: ReactNode;
}

const Accessibility = () => {
  return (
    <div
      style={{
        fontSize: "1em",
      }}
    >
      <h2 id="modal-title">ACCESSIBILITY STATEMENT</h2>
      <p>
        We are committed to providing an optimal user experience for everyone.
        To this end, we have also committed to achieving and maintaining full
        accessibility for all of our online properties and services. Our goal is
        for these websites and services to be accessible for people with
        disabilities wherever possible.
      </p>
      <p>
        To achieve this, we have partnered with Perspective Tester, a web
        accessibility consultant. This partnership provides for regular testing
        of our digital assets using assistive technologies including but not
        limited to screen readers. This testing is performed to the standards
        defined in the Web Content Accessibility Guidelines version 2.1, level
        AA criteria, bringing our sites into conformance with the Americans with
        Disabilities Act of 1990. All aspects of our sites, including our forms
        and policy pages, undergo regular testing against these WCAG
        requirements, which also provides conformance with the California
        Consumer Privacy Act accessibility requirements.
      </p>
      <p>
        We have taken the following measures to support accessibility across our
        digital assets:
      </p>
      <ul>
        <li>
          Include accessibility as an objective for our Design, UX, Marketing,
          Development and Legal teams
        </li>
        <li>Include accessibility in our procurement process</li>
        <li>
          Provide ongoing training for our Design, UX, Marketing, Development
          and Legal teams
        </li>
        <li>
          Provide regular manual testing with assistive technologies for all
          digital assets
        </li>
        <li>Include people with disabilities in the testing process</li>
      </ul>
      <p>
        Please be aware that our process of testing and remediation is ongoing.
        We test with multiple device combinations and a variety of assistive
        technologies. We strive to achieve the highest level of accessibility in
        all areas but understand due to the ever-changing landscape of the Web
        and the variety of assistive technologies available occasional issues
        may arise for a particular user. Should this happen to you please
        contact us at the phone number or email address above. Please specify
        the location and nature of the issue and we will respond in a timely
        manner and provide assistance.
      </p>
      <p>
        Our websites provide links to companies and services outside of our
        organization. This includes our partners and affiliates. We encourage
        all sites in our network to be compliant with the accessibility
        requirements but we have no control over these sites and cannot assure
        their accessibility.
      </p>
      <p>
        We are committed to making the information and services on its public
        websites accessible to all users. We strive to make our sites accessible
        by people with disabilities and offer assistance when needed. If you are
        having trouble navigating our websites because of a disability please
        email us at{" "}
        <a href="mailto:admin@printfingertech.com">admin@printfingertech.com</a>
        .
      </p>
    </div>
  );
};

export const Layout = ({ children }: Props) => {
  const { Modal, openModal, setChildren, closeModal } = useModal({
    maxWidth: "90vw !important",
  });

  useEffect(() => {
    setChildren(
      <Fragment>
        <Accessibility />
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
        </div>
      </Fragment>
    );
  }, []);

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Main>
      <Header />
      <DoubleCenter>
        <Center>
          {children}
          <Footer>
            <div>
              <a
                href="#"
                onClick={() => {
                  openModal();
                }}
              >
                Accesibility Statement
              </a>
            </div>
            <div>
              <p>
                Copyright © {getYear()} Powered By: Print Finger Technologies,
                LLC
              </p>
            </div>
          </Footer>
        </Center>
      </DoubleCenter>
      <Image />
      <Modal />
    </Main>
  );
};

const Main = styled.main`
  ${styles.flex.column};
  align-items: center;
  height: 100vh;
  background-color: ${styles.color.grey_l4};
  z-index: 10;
  h1 {
    font-size: calc(30px + 1vw);
    margin: 1.5rem 0;
  }
  h2 {
    font-size: calc(24px + 1vw);
  }
  h3 {
    font-size: calc(18px + 1vw);
  }
  .card-header:hover {
    cursor: pointer;
  }
`;

const DoubleCenter = styled.div`
  width: 100vw;
  z-index: 20;
  height: calc(100vh - 80px);
  overflow: auto;
  display: flex;
  justify-content: center;
`;

const Center = styled.div`
  width: 70vw;
  @media screen and (max-width: 1440px) {
    width: 90vw;
  }
  justify-content: center;
`;

const Image = styled.div`
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  background-color: ${convertColor.alpha(styles.color.primary, 0.1)};
  mask-image: url("https://svgsilh.com/svg/159600.svg");
  mask-size: 300px 200px;
  mask-repeat: repeat;
`;

const Footer = styled.div`
  width: 100%;
  height: 100px;
  padding-top: 25px;
  display: block;
  color: ${styles.color.primary};
  div {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    ${styles.flex.center};

    h2 {
      font-size: 2em;
    }
    p,
    ul {
      font-size: 1em;
    }
    p {
      text-align: center;
    }
  }
`;
