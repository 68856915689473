import { Button, Modal } from "@material-ui/core";
import React, { CSSProperties, ReactNode, useState } from "react";
import styled from "styled-components";
import { styles } from "../styles";

interface State {
  isOpen: boolean;
  children: ReactNode;
}

export const useModal = (
  style?: CSSProperties
): {
  Modal: () => JSX.Element;
  closeModal: () => void;
  openModal: () => void;
  setChildren: (c: ReactNode) => void;
} => {
  const [children, setChildren] = useState<State["children"]>(<div />);
  const [isOpen, setIsOpen] = useState<State["isOpen"]>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const modal = () => (
    <Modal open={isOpen}
      style={{
        width:  '90vw',
        height: '90vh',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
      }}
      aria-labelledby="modal-title"
      disableEscapeKeyDown={false}>
      <S.Modal style={style}>{children}</S.Modal>
    </Modal>
  );

  return { Modal: modal, closeModal, openModal, setChildren };
};

const S = {
  CancelButton: styled.button`
    ${styles.button.base};
    ${styles.button.error};
  `,
  ConfirmButton: styled.button`
    ${styles.button.base};
    ${styles.button.success};
  `,
  Modal: styled.div`
    background-color: ${styles.color.white};
    padding: 40px;
    height: 100%;
    text-align: center;
    div {
      text-align: left;
    }
  `,
  Buttons: styled.div`
    margin-top: 40px;
    ${styles.flex.row};
    justify-content: space-between;
  `,
};
