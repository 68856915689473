import React from "react";
import styled from "styled-components";
import { styles } from "../styles";
import { convertColor } from "../common/utils";

export const Header = () => {
  return (
    <StyledHeader>
      <div />
      <h1>Consumer Privacy Request Form</h1>
      <div />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background-color: ${styles.color.primary};
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 10vw 1fr 10vw;
  border: none;
  h1 {
    z-index: 1;
    color: ${styles.color.white};
    text-align: center;
    font-weight: bold;
    margin: 0px;
  }
`;