import { css } from "styled-components";

const leftMargin = css`
  > :first-child {
    margin-right: 4px;
  }
`;

export const general = {
  leftMargin,
};
