import { css } from "styled-components";

const column = css`
  display: flex;
  flex-direction: column;
`;

const row = css`
  display: flex;
  flex-direction: row;
`;

const center = css`
  justify-content: center;
  align-items: center;
`;

export const flex = {
  column,
  row,
  center,
};
