import { color } from "./color";

const summary = {
  color: color.white,
  fontWeight: "bold" as "bold",
  fontSize: "2em",
};

const details = {
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  padding: "0px",
};

export const panel = {
  summary,
  details,
};
